<template>
  <div>
    <el-card shadow="hover">
      <!-- 客户发送统计 -->
      <!-- 查询条件 -->
      <el-form :model="searchForm" ref="searchFormRef" inline>
        <el-form-item :label="$t('时间段')" prop="">
          <el-date-picker
            v-model="searchForm.startTime"
            type="date"
            :placeholder="$t('开始时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
          -
          <el-date-picker
            v-model="searchForm.endTime"
            type="date"
            :placeholder="$t('结束时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <!-- <el-form-item :label="$t('用户账号')" prop="">
          <el-input
            v-model.trim="searchForm.userName"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.userName = ''"
            style="width: 200px"
          />
        </el-form-item> -->
        <el-form-item :label="$t('应用编号')" prop="">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="" prop="">
          <!-- <el-switch
              v-model="searchForm.searchMode"
              :active-value="1"
              :inactive-value="0"
              style="margin: 0 30px"
            >
            </el-switch> -->
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("查询") }}</el-button
          >
          <el-button type="primary" @click="exportExcel" :loading="btnLoading"
            ><i class="fa-solid fa-download"></i>{{ $t("导出数据") }}</el-button
          >
        </el-form-item>
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        style="width: 100%"
        border
        class="elTable"
        height="var(--height)"
        :key="tableKey"
        v-loading="loading"
      >
        <template #empty>
          <el-empty />
        </template>
        <el-table-column
          prop="userName"
          :label="$t('用户账号')"
          align="center"
        />
        <el-table-column
          prop="enterprise"
          :label="$t('公司名称')"
          align="center"
        />
        <el-table-column prop="appId" :label="$t('应用编号')" align="center" />
        <el-table-column
          prop="startTime"
          :label="$t('开始时间')"
          align="center"
        >
          <template #default="scope">
            <div v-if="scope.row.startTime">
              {{ dayjs(scope.row.startTime).format("YYYY-MM-DD") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" :label="$t('结束时间')" align="center">
          <template #default="scope">
            <div v-if="scope.row.endTime">
              {{ dayjs(scope.row.endTime).format("YYYY-MM-DD") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="total" :label="$t('发送总量')" align="center" />
        <el-table-column
          prop="unsubmitted"
          :label="$t('未提交')"
          align="center"
        />
        <el-table-column
          prop="intercepted"
          :label="$t('拦截')"
          align="center"
        />
        <el-table-column prop="black" :label="$t('黑名单')" align="center" />
        <el-table-column
          prop="rejected"
          :label="$t('审核失败')"
          align="center"
        />
        <el-table-column
          prop="success"
          :label="$t('发送成功')"
          align="center"
        />
        <el-table-column
          prop="failure"
          :label="$t('发送失败')"
          align="center"
        />
        <el-table-column
          prop="unknown"
          :label="$t('未知状态')"
          align="center"
        />
        <el-table-column
          prop="unknownRatio"
          :label="$t('未知率')"
          align="center"
        >
          <template #default="scope">
            {{ numberTool(scope.row.unknownRatio * 100) }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="successRatio"
          :label="$t('成功率')"
          align="center"
        >
          <template #default="scope">
            {{ numberTool(scope.row.successRatio * 100) }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          :label="$t('单价') + `（${$t('元')}）`"
          align="center"
        />
        <el-table-column
          prop="pay"
          :label="$t('消费金额') + `（${$t('元')}）`"
          align="center"
        />
        <!-- <el-table-column prop="billType" :label="$t('计费方式')" align="center">
          <template #default="scope">
            {{ idToName(scope.row.billType, dic.billType) }}
          </template>
        </el-table-column> -->
        <el-table-column prop="payType" :label="$t('付费方式')" align="center">
          <template #default="scope">
            {{ idToName(scope.row.payType, dic.payType) }}
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "customerSend",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref, watch, nextTick } from "vue";
import {
  getCustomerStatisticsList,
  exportCustomerStatisticsList,
} from "@/http/api/statisticalManage";
// import { getSmsProductList } from "@/api/productManage.js";
// import { getSmsCustomerList } from "@/api/statisticalManage.js";
import dayjs from "dayjs";
import { numberTool, idToName, binaryToExcelExport } from "@/utils/tool";
import dic from "@/utils/dictionary";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// ==========================表格列显示隐藏============================
const checkList = ref([
  t("应用编号"),
  t("开始时间"),
  t("结束时间"),
  t("发送总量"),
  t("未提交"),
  t("拦截"),
  t("黑名单"),
  t("审核失败"),
  t("发送成功"),
  t("发送失败"),
  t("未知状态"),
  t("未知率"),
  t("成功率"),
  //t("单价") + `（${t("元")}）`,
  // t("消费金额") + `（${t("元")}）`,
  // t("计费方式"),
  // t("付费方式"),
]);
// const checkVal = ref([]);
const tableKey = ref(1);
// 查询表单
const searchForm = reactive({
  startTime: dayjs().startOf("date").format(),
  endTime: dayjs().startOf("date").format(),
  userName: "",
  appId: "",
  searchMode: 0,
  page: 1,
  pageSize: 100,
});
// 表格数据
const loading = ref(false);
const tableData = ref([]);
let total = ref(0);
onBeforeMount(async () => {
  query();
});
let spanArr = []; //用于存放每一行记录的合并数
let pos = 0; //spanArr的索引
// let spanArr2 = [];
// let pos2 = 0;
const query = async () => {
  loading.value = true;
  spanArr = [];
  pos = 0;
  // spanArr2 = [];
  // pos2 = 0;
  const res = await getCustomerStatisticsList(searchForm);
  if (res.code === 0) {
    let arr = res.data.list || [];
    total.value = res.data.total - 1; //减去末尾的合计行
    tableData.value = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].appStats) {
        for (var x = 0; x < arr[i].appStats.length; x++) {
          tableData.value.push({
            userName: arr[i].userName,
            enterprise: arr[i].enterprise,
            ...arr[i].appStats[x],
          });
        }
      }
    }

    for (var i = 0; i < tableData.value.length; i++) {
      if (i === 0) {
        spanArr.push(1);
        pos = 0;
        // spanArr2.push(1);
        // pos2 = 0;
      } else {
        // 判断当前元素与上一个元素是否相同
        if (tableData.value[i].userName === tableData.value[i - 1].userName) {
          spanArr[pos] += 1;
          spanArr.push(0);
          // spanArr2[pos2] += 1;
          // spanArr2.push(0);
        } else {
          spanArr.push(1);
          pos = i;
          // spanArr2.push(1);
          // pos2 = i;
        }
      }
    }
  }
  loading.value = false;
};
//===============================合并行===============================
const objectSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  if (columnIndex === 0 || columnIndex === 1) {
    const _row = spanArr[rowIndex];
    const _col = _row > 0 ? 1 : 0;
    return {
      rowspan: _row,
      colspan: _col,
    };
  }
};
//===============================导出数据===============================
const btnLoading = ref(false);
const exportExcel = async () => {
  btnLoading.value = true;
  const res = await exportCustomerStatisticsList(searchForm);
  // if (res && res instanceof Blob) {
  //   binaryToExcelExport(res, t("客户发送统计报表"));
  // }
  btnLoading.value = false;
};
</script>

<style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 250px);
}
::v-deep(label) {
  margin-bottom: 0;
}
</style>
  